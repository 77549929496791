const odinIcon = require('./assets/odin-icon.svg');

let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Frank Zhang",
    "role": "full-stack web developer",
    "designPortfolio":" yunfrankzhang.com",
    "skypeid": "Your skypeid",
    "roleDescription": " Current tech stack: front end (ReactJs), back end (Node, Express), database (MongoDB) ",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/frank-zhang-dev/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/FrankZhang2046",
          "className":"fa fa-github"
        },
        {
          "name":"twitter",
          "url":"https://www.youtube.com/channel/UCLG_lIjyLPqjN-KtHK_aE-Q",
          "className":"fa fa-youtube"
        }
      ],
    "aboutme":"Originally trained as an architect, design and visualization are my strong suits. Having acquired the soft skills necessary to work in corporations from my prior working experiences, I decided to pursue the career path as a web developer to fulfill my passion towards technology and constant learning.",
    "address":" frankbusinessmail@gmail.com",
    "website":"https://github.com/FrankZhang2046",
    "education":[
      {
        "UniversityName":"BrainStation Coding Bootcamp",
        "specialization":"Full Stack Web Developer",
        "MonthOfPassing":"Jun",
        "YearOfPassing":"2019",
        // "Achievements":"Some Achievements"
      },
      {
        "UniversityName":"Universitory of Toronto Saint George Campus",
        "specialization":"Architectural Design",
        "MonthOfPassing":"Apr",
        "YearOfPassing":"2017",
        "Achievements":"Honors Bachelor of Art"
      }
    ],
    "work":[
      {
        "CompanyName":"MMC International Architects",
        "specialization":"Design Architect",
        "MonthOfLeaving":"Mar",
        "YearOfLeaving":"2018",
        "Achievements":"Contributed design proposals to major Canadian shopping centres including Square One, Erin Mills, etc."
      },
        {
        "CompanyName":"Qualicom Innovations",
        "specialization":"Frontend Web Developer",
        "MonthOfLeaving":"Oct",
        "YearOfLeaving":"2019",
        "Achievements":"Building web applications using Angular and React, data visualization with JavaScript libraries, designing user interfaces. "
      },
      // {
      //   "CompanyName":"Some Company",
      //   "specialization":"Some specialization",
      //   "MonthOfLeaving":"Jan",
      //   "YearOfLeaving":"2018",
      //   "Achievements":"Some Achievements"
      // }
    ],
    "skillsDescription":"My Tech Stack",
    "skills":[
      {
        "skillname":"MongoDB", "progress":"35%"
      },
      {
        "skillname":"NODE & EXPRESS", "progress":"60%"
      },
      {
        "skillname":"Reactjs", "progress":"80%"
      }
    ],
    "portfolio":[
      {
        "name":"Project - Oidn",
        "description":"Mobile client for Pocket and Feedly",
        "imgurl": {odinIcon}
      },
      // {
      //   "name":"project2",
      //   "description":"mobileapp",
      //   "imgurl":"images/portfolio/project.jpg"
      // },
      // {
      //   "name":"project3",
      //   "description":"mobileapp",  
      //   "imgurl":"images/portfolio/project2.png"
      // },
      // {
      //   "name":"project4",
      //   "description":"mobileapp",
      //   "imgurl":"images/portfolio/phone.jpg"
      // }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData
