import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">
          <div className="row section-head">
            <div className="ten columns">
              <p className="lead">
              Feel free to checkout my design portfolio
              </p>
            </div>
          </div>
          <div className="row">
            <aside className="eigth columns footer-widgets">
              <p className="widget">
              Design Porfolio :  
              <span style={{color:'lightGrey'}}>
                  <a href="https://yunfrankzhang.com" target="_blank">{resumeData.designPortfolio}</a>
                </span>
              </p>
            </aside>
          </div>
        </section>
        );
  }
}